import { ProgressCircle } from "components/common/progressCircle";
import { Holder } from "./holder";
import { about_heads } from "lib/constants";

export function About() {
  return <Holder id="about" child={<Page />} />;
}

function Page() {
  return (
    <div className="flex w-full px-6 text-black dark:text-white flex-col justify-center ">
      <h2 className="text-3xl sm:text-4xl mt-10 xl:mt-0">About Me</h2>
      <hr className="bg-gray-500 my-1" />
      <div className="text-lg sm:text-xl w-full text-justify m-0 p-0 mt-2">
        A solution-driven mindset is the key to achieving expected goals. One of
        my strengths is being able to visualize how different technologies fit
        together in the most elegant and fail-safe way to deliver reliable and
        relevant products.
      </div>

      {/*  <div className="flex w-full px-5"></div> */}
      {/* <div className="flex w-full justify-center gap-8 flex-wrap">
        {frameworks.map((framework) => {
          return (
            <ProgressCircle
              percents={90}
              stroke="#8b5cf6"
              src={framework.src}
            />
          );
        })}
      </div> */}
      <div className="w-full flex justify-center mt-5">
        <div className="flex w-full flex-wrap items-start justify-center">
          {about_heads.map((item) => {
            return (
              <div
                key={`${item.title}-holder`}
                className="flex justify-center w-full md:w-1/2 flex-col items-center my-5 px-5"
              >
                {item.icon}
                <h3 className="text-2xl mt-2 text-center">{item.title}</h3>
                <hr className="bg-gray-500 my-1 w-5/6" />
                <p className="text-center w-full mt-2 text-md">
                  {item.content}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    // <>ABOUT</>
  );
}
