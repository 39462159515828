import Button from "components/common/button";
import { languages, socials } from "lib/constants";
import { FaEnvelope, FaGithub, FaLinkedin, FaPhoneAlt } from "react-icons/fa";

export function MainHolder() {
  // const [selectedId, setSelectedId] = useState<string | null>(null);
  return (
    <div className="flex flex-col grow justify-center items-center flex-wrap sm:flex-nowrap xl:h-3/4 xl:min-w-5/12  xl:w-5/12 px-5 sm:px-0 mt-2 mb-2 sm:mt-5 xl:my-16 lg:rounded-xl bg-white dark:bg-gray-800 drop-shadow-2xl py-5 text-black dark:text-white">
      {/* <div className="flex flex-col grow items-center justify-center w-full "> */}
      <img
        src="/profile/profile-4-min.jpg"
        className="rounded-2xl w-48 sm:w-52 h-48 sm:h-52"
        alt="Aastik Saini"
      />
      <div className="flex items-center w-full justify-center gap-2 sm:gap-4 px-3">
        <div className="text-center flex flex-col items-center">
          <h1 className="text-4xl sm:text-5xl mt-5">Aastik Saini</h1>
          <hr className="bg-gray-500 my-1 w-11/12" />
          <h2 className="text-xl sm:text-3xl text-gray-700 dark:text-gray-200">
            Building is but a skill.
          </h2>
          <div className=" flex flex-wrap w-4/5 md:w-full justify-center mt-1 sm:mt-4">
            {languages.map((main, index) => {
              return (
                // <>
                //   <motion.div
                //     layoutId={main.name}
                //     onClick={() => setSelectedId(main.name)}
                //   >
                <div title={main.name}>{main.icon}</div>
                // <img
                //   key={`tech-${index}`}
                //   src={`${main.src}`}
                //   className="h-8 w-8 sm:w-12 sm:h-12"
                //   alt={`${main.name}`}
                // />
                //   </motion.div>
                // </>
              );
            })}
          </div>
        </div>
      </div>

      <hr className="bg-gray-500 mt-4 w-2/3" />
      <h2 className="text-lg sm:text-2xl text-gray-700 dark:text-gray-200">
        Socials
      </h2>
      <div className="flex gap-2 my-2 flex-wrap w-3/4 md:w-11/12 justify-center text-xl">
        <a
          href="tel:+919355355599"
          data-rel="external"
          className="bg-purple-200 text-black hover:bg-purple-500 px-2 rounded-full hover:text-white cursor-pointer flex items-center gap-1 py-0.5"
        >
          <FaPhoneAlt className="mt-0.5" size={18} />
          +91 93553 55599
        </a>
        <a
          href="mailto:saini.aastik@gmail.com"
          data-rel="external"
          className="bg-purple-200 text-black hover:bg-purple-500 px-2 rounded-full hover:text-white cursor-pointer flex items-center gap-2 py-0.5"
        >
          <FaGithub className="mt-0.5" size={18} />
          DMR-8
        </a>
        <a
          href="mailto:saini.aastik@gmail.com"
          data-rel="external"
          className="bg-purple-200 text-black hover:bg-purple-500 px-2 rounded-full hover:text-white cursor-pointer flex items-center gap-2 py-0.5"
        >
          <FaLinkedin className="mt-0.5" size={18} />
          aastik-saini
        </a>
        <a
          href="mailto:saini.aastik@gmail.com"
          data-rel="external"
          className="bg-purple-200 text-black hover:bg-purple-500 px-2 rounded-full hover:text-white cursor-pointer flex items-center gap-2 py-0.5"
        >
          <FaEnvelope className="mt-0.5" size={18} />
          saini.aastik@gmail.com
        </a>
      </div>
      {/* <div className="py-2 flex gap-4 flex-wrap items-center">
        {socials.map((item, index) => {
          return (
            <a
              aria-label={`social-${index}`}
              key={`social-${index}`}
              href={item.url}
              target="_blank"
            >
              {item.icon}
            </a>
          );
        })}
      </div> */}
      {/* <AnimatePresence>
          {selectedId && (
            <motion.div
              layoutId={selectedId}
              className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
              <motion.div className="relative w-full max-h-full">
                <motion.div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                  {frameworks[selectedId].map((item: any) => {
                    return (
                      <motion.img
                        src={`${item.src}`}
                        className="h-8 w-8 sm:w-12 sm:h-12"
                        alt={`${item.name}`}
                      />
                    );
                  })}
                </motion.div>
                <motion.button onClick={() => setSelectedId(null)}>
                  Close{" "}
                </motion.button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence> */}
    </div>
    //{" "}
  );
}
