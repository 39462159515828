import {
  FaEnvelope,
  FaGithub,
  FaLinkedinIn,
  FaPhoneSquareAlt,
  FaSpotify,
} from "react-icons/fa";

import {
  BiLogoVuejs,
  BiLogoReact,
  BiLogoGoLang,
  BiLogoAndroid,
  BiLogoAngular,
  BiLogoFlutter,
  BiLogoPython,
  BiLogoDjango,
  BiLogoCPlusPlus,
  BiLogoTypescript,
  BiLogoFlask,
  BiLogoDocker,
  BiLogoJavascript,
} from "react-icons/bi";

export const languages = [
  {
    icon: <BiLogoReact size="50" className="text-cyan-400" />,
    name: "React",
  },
  {
    icon: <BiLogoAngular size="50" className="text-red-600" />,
    name: "Angular",
  },
  {
    icon: <BiLogoTypescript size="50" className="text-blue-600" />,
    name: "Typescript",
  },
  {
    icon: <BiLogoPython size="50" className="text-amber-400" />,
    name: "Python",
  },
  {
    icon: <BiLogoDjango size="50" className="text-green-600" />,
    name: "Django",
  },
  {
    icon: <BiLogoCPlusPlus size="50" className="text-purple-700" />,
    name: "C++",
  },
  {
    icon: <BiLogoFlutter size="50" className="text-blue-400" />,
    name: "Flutter",
  },
  {
    icon: <BiLogoDocker size="50" className="text-blue-600" />,
    name: "Docker",
  },
  {
    icon: <BiLogoFlask size="50" className="text-gray-800" />,
    name: "Flutter",
  },
];

export const socials = [
  // {
  //   icon: (
  //     <FaPhoneSquareAlt
  //       size="36"
  //       className="text-green-500 hover:text-gray-700"
  //     />
  //   ),
  //   url: "tel:+919355355599",
  // },
  {
    icon: (
      <FaLinkedinIn size="36" className="text-blue-500 hover:text-gray-700" />
    ),
    url: "https://linkedin.com/in/aastik-saini",
    title: "aastik-saini",
  },
  {
    icon: (
      <FaGithub size="36" className="text-violet-500 hover:text-gray-700" />
    ),
    url: "https://github.com/DMR-8",
    title: "DMR-8",
  },

  {
    icon: (
      <FaEnvelope size="36" className="text-blue-500 hover:text-gray-700" />
    ),
    url: "mailto:saini.aastik@gmail.com",
  },
  // {
  //   icon: (
  //     <FaSpotify size="36" className="text-green-500 hover:text-gray-700" />
  //   ),
  //   url: "https://open.spotify.com/user/aastikkaloti",
  // },
];
