export const resume_content = [
  {
    logo: "/companies/autotext.png",
    title: `Co-Foudner & CTO`,
    company_name: "AutoText",
    tenure: "Mar 2023 - Present",
    content:
      "Developed state of the art technologies to generate and manage your social media content and posting schedules to improve performance on social media platforms",
    url: "https://autotext.app",
  },

  {
    logo: "/companies/legalpay.png",
    title: `Asst. Vice President`,
    company_name: "LegalPay",
    tenure: "Oct 2022 - Feb 2024",
    content:
      "Orchestrated technological advancement and spearheaded digital transformation initiatives, elevating operational efficiency and customer experiences to new heights.",
    url: "https://legalpay.in",
  },
  {
    logo: "/companies/etha.png",
    title: `Co-Founder & CTO`,
    company_name: "Etha - Future of Politics",
    tenure: "Jan 2021 - Sept 2022",
    content:
      "Led technical innovation and strategy, driving transformative solutions and optimizing performance to achieve unprecedented growth.",
    url: "https://etha.one",
  },
  {
    logo: "/companies/holosuit.png",
    title: `Technical Lead`,
    company_name: "HoloSuit",
    tenure: "Jan 2019 - Aug 2020",
    content:
      "Guided and empowered cross-functional teams, delivering cutting-edge XR solutions and fostering a culture of excellence in product development.",
    url: "https://holosuit.com",
  },

  //   {
  //     icon: (
  //       <FaToolbox size="46" className="text-purple-600 dark:text-purple-400" />
  //     ),
  //     title: `Versatile Skillset`,
  // content: `Knowledge of multiple domains help solve problems effeciently`,
  //   },
];
