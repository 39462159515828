import { FaExternalLinkAlt } from "react-icons/fa";
import { Holder } from "./holder";
import { projects } from "lib/constants";

export function Projects() {
  return <Holder id="projects" child={<Page />} />;
}
function Page() {
  return (
    <div className="flex w-full px-6 text-black dark:text-white flex-col justify-center">
      <h2 className="text-3xl sm:text-4xl mt-10 xl:mt-0">Hobby Projects</h2>
      <hr className="bg-gray-500 my-1" />

      <div className="w-full flex justify-center mt-5 mb-5 xl:mb-0">
        <div className="grid w-full flex-wrap lg:grid-cols-2 gap-2 justify-center">
          {projects.map((item) => {
            return (
              <a
                key={`${item.title}-holder`}
                href={item.link}
                target="_blank"
                className="w-full bg-gray-100 dark:bg-gray-500 rounded-xl py-2 group"
              >
                <div className="flex gap-2 items-center px-2">
                  {item.technology}
                  <h3 className="text-2xl font-bold flex items-center group-hover:text-purple-700">
                    {item.title}&nbsp;&nbsp;
                    <FaExternalLinkAlt size="16" />
                  </h3>
                </div>
                <hr />
                <div className="px-2">{item.desc}</div>
              </a>
              // <div
              //   key={`${item.company_name}-holder`}
              //   className="w-full sm:w-1/2 my-2"
              // >
              //   <div className="bg-purple-200 rounded-2xl dark dark:bg-purple-600 flex items-center px-2 text-sm h-fit w-44 text-center justify-center mb-2">
              //     {item.tenure}
              //   </div>
              //   <img
              //     src={`${item.logo}`}
              //     className="h-12 bg-white rounded-lg"
              //     alt={`${item.company_name}-logo`}
              //   />
              //   <h3 className="text-2xl font-bold">{item.title}</h3>
              //   <hr className="w-11/12" />
              //   <div className=" mt-2 mb-3 w-11/12">{item.content}</div>
              // </div>
              // <>aaa</>
            );
          })}
        </div>
      </div>
    </div>
  );
}
