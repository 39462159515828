import {
  FaChartLine,
  FaDraftingCompass,
  FaRobot,
  FaSwatchbook,
} from "react-icons/fa";

export const about_heads = [
  {
    icon: (
      <FaDraftingCompass
        size="46"
        className="text-purple-600 dark:text-purple-400"
      />
    ),
    title: `Technology Architect`,
    content: `Develop technical architectures for startups and SMEs. Help companies scale better and efficeintly using Technology.`,
  },
  {
    icon: (
      <FaSwatchbook
        size="46"
        className="text-purple-600 dark:text-purple-400"
      />
    ),
    title: `Product Design`,
    content: `Create, Optimize and Pivot your products to meet market needs. Redirect your products towards customer experience.`,
  },
  {
    icon: (
      <FaChartLine size="46" className="text-purple-600 dark:text-purple-400" />
    ),
    title: `Digital Marketing`,
    content: `Make informed decisions based on extensive data analysis. Generate relevant content for your social media audience.`,
  },
  {
    icon: (
      <FaRobot size="46" className="text-purple-600 dark:text-purple-400" />
    ),
    title: `Artificial Intelligence`,
    content: `Solve your daily problems effeciently using AI/ML Models. Develop custom models to serve your needs.`,
  },
  //   {
  //     icon: (
  //       <FaToolbox size="46" className="text-purple-600 dark:text-purple-400" />
  //     ),
  //     title: `Versatile Skillset`,
  // content: `Knowledge of multiple domains help solve problems effeciently`,
  //   },
];
